
.ats-subheader-container {
  display: flex;
  align-items: center;
  margin-top: 14vh;
  justify-content: space-between;
  padding: 0 2rem;
  background: white;
  border-bottom: 1px solid #e0e0e0;
  /* background-color: red; */
}

/* Left - Title & Search */
.ats-subheader-title {
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.ats-subheader-heading-search-con {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ats-subheader-search-wrapper {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  width: 20vw;
}

.ats-subheader-search-icon {
  font-size: 1.2rem;
  color: gray;
}

.ats-subheader-search {
  border: none;
  outline: none;
  background: transparent;
  width: 100%;
}

/* Right - Buttons */
.ats-subheader-buttons {
  display: flex;
  gap: 1rem;
}

.ats-subheader-btn {
  background-color: transparent;
  color: white;
  border: none;
  /* padding: 0.6rem 1.5rem; */
  padding: 8px 18px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.3s;
}
.menu-icon{
background-color: white;
}
/* .ats-subheader-btn:hover{
color: #007bff;
background-color: #eaf5ff;
} */
.ats-subheader-dropdown {
  position: relative;
}
.winspire-welcome-text{
  color: rgb(51,51,51);
}
.winspire-username{
  color: rgb(51,51,51);
}
.ats-subheader-dropdown-menu {
  position: absolute;
  top: 56%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 0.5rem 0;
  list-style: none;
  width: max-content;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 9;
}

.ats-subheader-dropdown-menu li {
  padding: 0.5rem 3rem 0rem 1rem;
  
  cursor: pointer;
}
.ats-subheader-dropdown-menu li a {
  text-decoration: none; 
  color: black; 
  font-size: 14px; 
  font-weight: 500; 
display: block;

}

.ats-subheader-dropdown-menu li a:hover {
  width: 100%;
  background:#eaf5ff; 
}

/* .ats-subheader-dropdown-menu li {
  padding: 0.5rem 1rem;
  cursor: pointer;
  
}

.ats-subheader-dropdown-menu li:hover {
  background:#eaf5ff;
} */


/* Edit Widget Modal Styles */

/* .ats-subheader-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end; 
  background: rgba(0, 0, 0, 0.2); 
  z-index: 10;
  visibility: hidden; 
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
} */

.ats-subheader-modal-overlay {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 9;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
}


.ats-subheader-modal {
  background: white;
  width: 40vw;
  transform: translateX(100%);
  transition: transform 0.3s ease-in-out;
  border-radius: 10px 0 0 10px;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.2);
  margin: 0px 20px;


 
}


.ats-subheader-modal-overlay.active {
  visibility: visible;
  opacity: 1;
}

.ats-subheader-modal-overlay.active .ats-subheader-modal {
  transform: translateX(0); 
}

.ats-subheader-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  background-color: #2C3E50;
  padding: 0 1rem;
  margin: 0;
  height: 45px;
  border-radius: 5px 5px 0 0;
}

.ats-subheader-modal-close {
  font-size: 1.5rem;
  cursor: pointer;
}


.ats-subheader-modal-content {
  margin-top: 1rem;
  padding: 1.5rem;
}


.ats-subheader-widgets {
  display: grid;
  grid-template-columns: repeat(3, 1fr); 
  gap: 1rem;
}

.ats-subheader-widget {
  outline: 1px dashed #007bff;
  position: relative;
  padding: 0.5rem;
  border-radius: 5px;
  text-align: center;
  width: 100px;
}

.ats-subheader-widget-close {
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: #007bff;
  color: white;
  font-size: 12px;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ats-subheader-widget-icon {
  font-size: 1rem;
  padding: 0.8rem;
  border: 1px solid #007bff;
  border-radius: 5px;
}

.ats-subheader-widget-icon-name {
  padding: 0;
  margin: 0;
  font-size: smaller;
  

}
.ats-subheader-modal-footer{
  display: flex;
 

}
.ats-subheader-modal-actions{
  display: flex;
  justify-content: space-between;
 
}

.ats-subheader-footer-cancelbtn{
  background-color: white;
  padding: 0.5rem 2rem;
  border:1px solid #007bff;
  border-radius: 5px;
}
.ats-subheader-footer-savebtn{
  background-color: #eaf5ff;
  padding: 0.5rem 2rem;
  border:1px solid #007bff;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .ats-subheader-container {
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    align-items: center;
  }

  .ats-subheader-heading-search-con {
    flex-direction: row;
    align-items: center;
    width: 100%;
   
  }

  .ats-subheader-search-wrapper {
    width: 100%;
  }

  .ats-subheader-title {
    font-size: 20px;
    text-align: center;
  }

  .ats-subheader-buttons {
    flex-direction: column;
    width: 100%;
    
  }

  .ats-subheader-btn {
    width: 100%;
    text-align: center;
  }


  .ats-subheader-widgets {
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    gap: 1rem;
    justify-content: center;
  }

  .ats-subheader-widget {
    width: 100%;
    max-width: 120px;
    text-align: center;
  }


  .ats-subheader-modal {
    width: 90vw;
    margin: 0;
    border-radius: 10px;
  }

 

  .ats-cancel-btn, 
  .ats-save-btn {
    width: 100%;
   
  }
}
.ats-subheader-footer-widget{
  margin: 1rem;

  text-align: center;
}


@media (max-width: 480px) {
  .ats-subheader-title {
    font-size: 18px;
  }

  .ats-subheader-search-wrapper {
    padding: 0.4rem 0.8rem;
  }

  .ats-subheader-btn {
    font-size: 0.9rem;
    padding: 0.5rem;
  
  }

  .ats-subheader-modal {
    width: 95vw;
  }

  .ats-subheader-widget {
    width: 90px;
    font-size: 12px;
  }
}
