.dbc-main-container{
   
    width: 100%;
   
}
.dbc-container{
    display: flex;
    flex-direction: column;
    padding: 0px 20px;
    

}
.dbc-cards-container {
    display: flex;
    gap: 20px;
    justify-content: center;
   margin-top: 1.5rem;
  
  }
  
  .dbc-card {
    flex: 1;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid;
    background: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
  }
  .dbc-card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }
  
  .dbc-icon {
    font-size: 24px;
    border: 1px solid #ddd;
    padding: 7px;
    border-radius: 6px;
  }
  
  .dbc-icon-purple { color: #7b1fa2; }
  .dbc-icon-green { color: #388e3c; }
  .dbc-icon-blue { color: #0288d1; }
  
  .dbc-card-purple { border-color: #7b1fa2; }
  .dbc-card-green { border-color: #388e3c; }
  .dbc-card-blue { border-color: #0288d1; }
  
  .dbc-card-title {
    font-size: 12px;
    font-weight: bold;
    color: #333;
    flex-grow: 1;
    margin-left: 10px;
  }
  
  .dbc-menu {
    font-size: 12px;
    cursor: pointer;
  }
  
  .dbc-card-body {
    display: flex;
    flex-direction: column;
    gap: 18px;
  }
  
  .dbc-card-stat {
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #666;
  }
  
  .dbc-stat-value {
    cursor: pointer;
    font-weight: bold;
    color: #000;
  }
 
  @media (max-width: 768px) {
    .dbc-cards-container {
      flex-direction: column;
      align-items: center;
    }
  
    .dbc-card {
      width: 90%;
      max-width: 320px;
    }
  }