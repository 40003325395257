.winspire-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #004582;
  padding: 10px 20px;
  height: 4vh; /* Ensure it has a fixed height */
  color: white;
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 12; 
}
.winspire-header-left {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.user-info {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.profile-icon {
  display: flex;
  align-items: center;
  gap: 10px;
}

.winspireheader-profile-icon-img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  object-fit: cover;
}

.welcome-text {
  display: flex;
  justify-content: center;
  line-height: 1.2;
}

.welcome-text p {
  margin: 0;
  font-family: 'poppins';
font-weight: 400;
font-size: 16px;
line-height: 24px;
letter-spacing: 0%;

  font-size: 14px;
  color: white;
}

.winspire-username {
  margin: 0;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0%;  
}

.dropdown-arrow {
  margin-right: 2%;
  margin-top: 4px;
  font-size: 14px;
  color: white;
}
/* .profile-dropdown-arrow{
  margin-right: 2%;
} */
.user-menu {
  background-color: #fff;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 200px;
  position: absolute;
  top: 130%;
  right: -40%;
  z-index: 10;
}

.user-profile-box {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.user-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6a5acd;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
}

.user-avatar-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.logout-btn {
  background-color: transparent;
  border: none;
  color: #e74c3c;
  font-weight: 600;
  cursor: pointer;
  padding: 6px 0;
  text-align: left;
}



  
  .winspire-header-center {
    flex-grow: 1;
    margin-right: 8%;
    display: flex;
    justify-content: center;
  }
  .winspire-logo {
    margin-right: 20%;
    height: 22px;
    width: auto;
    object-fit: contain;
    border-color: red;
  }
  .winspire-header-right {
    display: flex;
    align-items: center;
    margin-right: 3%;
    gap: 15px;
  }
  
  .header-icon {
    font-size: 18px;
    cursor: pointer;
    color: white;
    padding: 0;
    margin: 0;
  }
  
  .header-icon:hover {
    color: #ddd;
  }
.winspire-header-center{
  text-decoration: none;
}
.header-icon-link{
  color: white;
}
.header-icon-wrapper {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.ats-subheader-dropdown {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.ats-subheader-btn {
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
.profile-placeholder{
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: #6a5acd;
  color: white;
  font-weight: 700;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-transform: uppercase;
}

  /* Responsive for mobile screens (max-width: 480px) */
@media (max-width: 480px) {
  .winspire-header {
    flex-direction: column;
    height: auto;
   padding: 0;
    text-align: center;
  
  }

  .winspire-header-left {
    flex-direction: column;
 
  }

  .winspire-header-center {
    order: -1;

  }

  .winspire-logo {
    height: 30px;
    margin: 0;
  }

  .winspire-header-right {
    flex-direction: row;
    justify-content: center;
  
  
  }

  .user-menu {
    left: 50%;
    transform: translateX(-50%);
    top: 60px;
    width: 80%;

  }

 
  
}

/* Responsive for large screens (1920px and above) */
@media (min-width: 1920px) {
  .winspire-header {
    max-width: 100%;
    padding: 0;
    margin: 0 auto;
    height: 7vh;

  }

  .winspire-logo {
    height: 50px;
  }

  .header-icon {
    font-size: 22px;
  }

  .welcome-text p {
    font-size: 24px;
  }

  .user-menu {
    top: 60px;
    left: 92%;
    min-width: 150px;
    padding: 15px;
  }

  .logout-btn {
    font-size: 16px;
    padding: 10px 16px;
  }
}

/* Extra large screens (3000px and above) */
@media (min-width: 3000px) {
  .winspire-header {
    max-width: 100%;
    height: 7vh;
  }

  .winspire-logo {
    height: 60px;
  }

  .header-icon {
    font-size: 26px;
  }

  .welcome-text p {
    font-size: 28px;
  }

  .user-menu {
    top: 80px;
    left: 94%;
    min-width: 180px;
    padding: 20px;
  }

  .logout-btn {
    font-size: 18px;
    padding: 12px 20px;
  }
}


.ats-subheader-dropdown-menu {
  position: absolute;
  background: white;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 6px;
  margin-top: 25px;
  z-index: 999;
  left: 100;
}
.ats-subheader-dropdown-menu li {
  list-style: none;
  margin-bottom: 6px;
}
.ats-subheader-dropdown-menu li a {
  color: #333;
  text-decoration: none;
}

.user-name{
  color: black;
}