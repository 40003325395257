/* === General Container === */
.setting-create-user-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 32px;
  border-radius: 12px;

  margin-top: 20px;
}
/* === Styled Heading === */
.setting-create-user-heading {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  margin: 24px 32px 12px;
  padding-bottom: 8px;
  border-bottom: 2px solid #1e88e5;
  display: inline-block;
  letter-spacing: 0.5px;
}

/* === Search Input === */
.setting-create-user-search {
  display: flex;
  align-items: center;
  width: 320px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background: #fff;
  padding: 6px 14px;
}

.setting-create-user-search:focus-within {
  border-color: #1e88e5;
}

.setting-createuser-search-icon {
  font-size: 16px;
  color: #888;
  margin-right: 8px;
}

.Setting-createsearch {
  flex: 1;
  padding: 8px 4px;
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
}

/* === Add Button === */
.setting-create-user-button .ftn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 24px;
  font-weight: 500;
  font-size: 14px;
  color: #1e88e5;
  background: white;
  border: 2px solid #1e88e5;
  border-radius: 30px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(30, 136, 229, 0.2);
  transition: all 0.3s ease;
}

.setting-create-user-button .ftn:hover {
  background: linear-gradient(to right, #1e88e5, #42a5f5);
  color: white;
  transform: scale(1.03);
}

.setting-create-user-icon {
  font-size: 16px;
}

/* === 🔒 PRESERVED ORIGINAL MODAL + VALIDATION STYLES === */
.Create-User-hidden-form {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%) scale(0.9);
  width: 80%;
  height: auto;
  max-width: 600px;
  z-index: 9;
  background: white;
  padding: 0 0 20px 0;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
  opacity: 0;
  z-index: 12;
  transition: left 0.5s ease-in-out, opacity 0.3s ease-in-out;
}
.Create-user-show-form {
  left: 80%;
  opacity: 1;
  transform: translate(-55%, -50%) scale(1);
}
.Create-Access-role-modal-header {
  background-color: #2c3e50;
  color: white;
  padding: 6px 15px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.Create-Access-role-modal-header button {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  cursor: pointer;
}
.setting-create-user-modal-body {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px;
  border-radius: 8px;
}
.setting-create-user-row {
  display: flex;
  gap: 16px;
}
.setting-create-user-input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.setting-create-user-col {
  flex: 1;
  display: flex;
  flex-direction: column;
}
.input-error-message {
  color: red;
  font-size: 12px;
  margin-top: 4px;
}
.setting-create-user-mobile {
  display: flex;
  gap: 8px;
  align-items: center;
}
.setting-create-user-code {
  width: 80px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}
.Create-User-Access-role-modal-footer {
  display: flex;
  margin-right: 8.5%;
  justify-content: center;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.Create-User-Access-role-cancel-button,
.Create-User-Access-role-save-button {
  padding: 8px 15px;
  margin-left: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}
.Create-User-Access-role-cancel-button {
  background: white;
  border: 1px solid #007bff;
  color: black;
}
.Create-User-Access-role-save-button {
  background-color: #007bff;
  color: white;
}
/* Validation error input style */
.error-input {
  border-color: #e53935 !important;
  background-color: #fff6f6;
}
.error-input::placeholder {
  color: #e53935;
}
.error-input:focus,
.error-input:not(:placeholder-shown) {
  border-color: #ccc !important;
  background-color: white !important;
  color: #333;
}
.error-input:focus::placeholder,
.error-input:not(:placeholder-shown)::placeholder {
  color: #aaa;
}

/* === Dropdown (unchanged) === */
.setting-create-user-dropdown {
  position: absolute;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  z-index: 50;
  padding: 6px 0;
  margin-top: 4px;
}
.setting-create-user-dropdown-item {
  position: relative;
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f0f0f0;
}
.setting-create-user-dropdown-item:hover {
  background-color: #f5faff;
}
.setting-create-user-dropdown-cancel {
  position: absolute;
  top: 8px;
  right: 10px;
  border: none;
  background: transparent;
  color: #e53935;
  font-size: 14px;
  cursor: pointer;
}
.setting-create-user-dropdown-label {
  font-size: 14px;
  font-weight: 600;
  color: #333;
  margin: 0;
}
.setting-create-user-dropdown-value {
  font-size: 12px;
  color: #777;
  margin: 2px 0 0 0;
}

/* === Table Styles === */
.settings-createuser-table-container {
  width: 100%;
  margin: 24px auto;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}
.settings-createuser-table-wrapper {
  max-height: 400px;
  overflow-y: auto;
}
.settings-createuser-table-wrapper::-webkit-scrollbar {
  width: 6px;
}
.settings-createuser-table-wrapper::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 6px;
}

.settings-createuser-table-wrapper::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.setting-create-user-manager {
  padding: 10px 12px;
  border-bottom: 1px solid #f0f0f0;
  cursor: pointer;
  background-color: white;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.setting-create-user-manager:hover {
  background-color: #f9f9f9;
}

.setting-create-user-cancel-btn {
  background-color: #f5f5f5;
  color: #333;
  border: none;
  padding: 6px 10px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 6px;
  cursor: pointer;
  align-self: flex-end;
}

.setting-create-user-cancel-btn:hover {
  background-color: #e0e0e0;
}

.settings-createuser-table {
  width: 100%;
  border-collapse: collapse;
}
.settings-createuser-table thead {
  background-color: #e3f2fd;
  position: sticky;
  top: 0;
  z-index: 2;
}
.settings-createuser-table th,
.settings-createuser-table td {
  padding: 14px 20px;
  text-align: left;
  font-size: 14px;
  color: #333;
  border-bottom: 1px solid #eee;
  white-space: nowrap;
}
.settings-createuser-table tbody tr:hover {
  background-color: #f5faff;
}

/* === Responsive === */
@media (max-width: 768px) {
  .setting-create-user-container {
    flex-direction: column;
    gap: 12px;
  }
  .setting-create-user-search,
  .setting-create-user-button .ftn {
    width: 100%;
    justify-content: center;
  }
  .Create-User-hidden-form {
    width: 95%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }
  .setting-create-user-modal-body {
    padding: 16px;
  }
  .setting-create-user-row {
    flex-direction: column;
    gap: 10px;
  }
  .settings-createuser-table-wrapper {
    overflow-x: auto;
  }
  .settings-createuser-table {
    min-width: 600px;
  }
}

.settings-createuser-btn:hover {
  background-color: #0056b3;
}
@media (max-width: 575px) {
  .setting-create-user-container {
    flex-direction: column;
    gap: 10px;
    align-items: stretch;
  }

  .setting-create-user-search {
    width: 100%;
    padding: 5px 10px;
  }

  .Setting-createsearch {
    font-size: 12px;
    padding-left: 25px;
  }

  .setting-create-user-button {
    width: 100%;
    text-align: center;
  }

  .setting-create-user-button .ftn {
    width: 100%;
    justify-content: center;
  }

  .Create-User-hidden-form {
    width: 95%;
    left: 50%;
    transform: translate(-50%, -50%) scale(1);
  }

  .setting-create-user-modal-body {
    padding: 15px;
  }

  .setting-create-user-row {
    flex-direction: column;
    gap: 10px;
  }

  .setting-create-user-input {
    width: 100%;
  }

  .settings-createuser-table-wrapper {
    overflow-x: auto;
  }

  .settings-createuser-table {
    min-width: 600px;
    font-size: 12px;
  }

  .settings-createuser-btn {
    width: 100%;
    text-align: center;
  }
}

/* Tablet Devices (max-width: 786px) */
@media (max-width: 786px) {
  .setting-create-user-container {
    flex-wrap: wrap;
  }

  .setting-create-user-search {
    width: 80%;
  }

  .setting-create-user-button {
    width: auto;
  }

  .Create-User-hidden-form {
    width: 85%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .settings-createuser-table-wrapper {
    max-height: 300px;
  }

  .settings-createuser-table th,
  .settings-createuser-table td {
    padding: 6px;
    font-size: 13px;
  }
}
